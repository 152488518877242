import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/modeliver-partner-app.svg"
import thumbnailEvent from "../../static/images/products.svg"
import peerToPeer from "../../static/images/gift-delivery.svg"
import thumbnailDelivery from "../../static/images/business.svg"

import fastDelivery from "../../static/images/business/fast-delivery.svg"
import customer from "../../static/images/business/customer.svg"
import ecommerce from "../../static/images/business/mobile-ecommerce.svg"
import dispatch from "../../static/images/business/computer.svg"
import sales from "../../static/images/business/bar-graph-satisfaction.svg"
import trackOrder from "../../static/images/business/lpg-tracking.svg"
import { Link } from "gatsby"
import { BUSINESS_SIGN_UP_FORM_URL, t } from "../utils/constant"
import { withTrans } from "../i18n/withTrans"

const uselater = (
  <div>
    <p>
      With online shopping, one of the things that worries both the seller and
      buyer is package delivery. They are worried that it will take time to get
      to the designated location, whether it will arrive in one piece and the
      costs of having it delivered.
    </p>
    <p>
      As most delivery companies ship the product to their nearest facility,
      it’s up to the customer to arrange for a home delivery. This means more
      costs for the customer and a longer waiting period for the package to
      arrive. We are here to bridge this gap by providing last mile delivery for
      the customers, regardless of where they are.
    </p>
    <p>
      With our services, customers do not have to worry how the package will get
      to their doorstep. We take care of that and we ensure that it gets to you
      in one piece.
    </p>
  </div>
)
const IndexPage = ({ t: trans, intl }) => (
  <Layout>
    <SEO title="Last-mile delivery solution for your business" />

    <div className={"page-header home"}>
      <div className="top-content">
        <h1>
          {t({ intl: trans, id: "headerTitleBusiness1" })}
          <br /> {t({ intl: trans, id: "headerTitleBusiness2" })}
        </h1>
      </div>
      <div className="top-image">
        <img alt={"Dashboard"} src={featureImage} />
      </div>
    </div>
    <div className={"call-to-action"}>
      <div className={"container"}>
        <div className={"call-to-action__content"}>
          <h2>{t({ intl: trans, id: "headerBecomePartner" })}</h2>
          <p>{t({ intl: trans, id: "textBecomePartner" })}</p>
        </div>

        <div className={"button"}>
          <a href={BUSINESS_SIGN_UP_FORM_URL} target={"_blank"}>
            {t({ intl: trans, id: "textSignUp" })}
          </a>
        </div>
      </div>
    </div>
    <div className={"container"}>
      <div className={"features"}>
        <div className={"feature__item"}>
          <div className={"column"}>
            <div className={"col-12 feature__center"}>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerWeDeliver" })}</h2>
              </div>
            </div>
            <div className={"col-12"}>
              <div className={"feature__content offerings"}>
                <p style={{ fontSize: 25, lineHeight: "35px" }}>
                  <p>{t({ intl: trans, id: "textWeDeliver1" })}</p>
                  <p>{t({ intl: trans, id: "textWeDeliver2" })}</p>
                  <p>{t({ intl: trans, id: "textWeDeliver3" })}</p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={"feature__item"}>
          <div className={"column"}>
            <div className={"col-12 feature__center"}>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerJoinUs" })}</h2>
              </div>
            </div>
            <div className={"col-12"}>
              <div className={"feature__content offerings"}>
                <p>
                  {t({ intl: trans, id: "textJoinUs1" })}
                  <br />
                  {t({ intl: trans, id: "textJoinUs2" })}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-4 first"}>
              <div className={"thumbnail"}>
                <img
                  alt={t({ intl: trans, id: "headerFastDelivery" })}
                  src={fastDelivery}
                />
              </div>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerFastDelivery" })}</h2>
                <p>{t({ intl: trans, id: "textFastDelivery" })}</p>
              </div>
            </div>

            <div className={"col-4"}>
              <div className={"thumbnail"}>
                <img
                  alt={t({ intl: trans, id: "headerExpandCustomer" })}
                  src={customer}
                />
              </div>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerExpandCustomer" })}</h2>
                <p>{t({ intl: trans, id: "textExpandCustomer" })}</p>
              </div>
            </div>
            <div className={"col-4"}>
              <div className={"thumbnail"}>
                <img
                  alt={t({ intl: trans, id: "headerTrackOrders" })}
                  src={trackOrder}
                />
              </div>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerTrackOrders" })}</h2>
                <p>{t({ intl: trans, id: "textTrackOrders" })}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-4 first"}>
              <div className={"thumbnail"}>
                <img alt={"Dispatch platform"} src={dispatch} />
              </div>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerDispatchPlatform" })}</h2>
                <p>{t({ intl: trans, id: "textDispatchPlaform" })}</p>
              </div>
            </div>
            <div className={"col-4"}>
              <div className={"thumbnail"}>
                <img
                  alt={t({ intl: trans, id: "headerEcommercePlatform" })}
                  src={ecommerce}
                />
              </div>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerEcommercePlatform" })}</h2>
                <p>{t({ intl: trans, id: "textEcommercePlatform" })}</p>
              </div>
            </div>

            <div className={"col-4"}>
              <div className={"thumbnail"}>
                <img
                  alt={t({ intl: trans, id: "headerIncreaseSales" })}
                  src={sales}
                />
              </div>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerIncreaseSales" })}</h2>
                <p>{t({ intl: trans, id: "textIncreaseSales" })}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={"call-to-action cta-bottom"}>
      <div className={"container"}>
        <div className={"call-to-action__content"}>
          <h2>
            {t({ intl: trans, id: "headerPowerBusiness1" })}
            <br />
            {t({ intl: trans, id: "headerPowerBusiness2" })}
          </h2>
          <p>{t({ intl: trans, id: "textPowerBusiness" })}</p>
        </div>

        <div className={"button"}>
          <a href={BUSINESS_SIGN_UP_FORM_URL} target={"_blank"}>
            {t({ intl: trans, id: "textSignUp" })}
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default withTrans(IndexPage)
